body, p, td, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .container{
  font-family: 'Times New Roman', sans-serif;
  color: #000;
}
body *{
  box-sizing: border-box;
}
.container {
  padding: 0 15px;
  @media ($bp-larger-than-tablet) {
    padding: 0 50px;
  }
}
.in-mobile {
  display: inline;
  @media ($bp-larger-than-tablet) {
    display: none;
  }
}
.in-desktop {
  display: none;
  @media ($bp-larger-than-tablet) {
    display: inline;
  }
}
a:hover{
  //color: rgba(165, 9, 3, 0.8);
  color: $blue;
}
.bold {
  font-weight: $font-weight-black;
}

.header{
  background-color: #eaacac;
  &__list{
    padding: 11px 0 11px;
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    @media ($bp-larger-than-desktop) {
      display: flex;
      justify-content: space-between;
      font-size: 24px;
    }
  }
  &__address{
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    @media ($bp-larger-than-desktop) {
      margin-bottom: auto;
      text-align: left;
      width: 33.33%;
      flex-shrink: 0;
      padding: 67px 20px 0 43px;
    }
  }
  &__logo{
    flex-shrink: 0;
    align-items: center;
    padding: 0 10px;
    text-align: center;
    margin-left: 0;
    @media ($bp-larger-than-desktop) {
      margin-bottom: auto;
      display: block;
      width: 33.33%;
      flex-shrink: 0;
      margin-left: 10px;
      padding: auto;
    }
    &--img{
      margin-bottom: 16px;
      max-width: 100%;
      align-items: center;
      @media ($bp-larger-than-desktop) {
        margin-bottom: auto;
      }
    }
    img{
      max-width:100%;
      height:auto;

    }
  }
  &__contacts{
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    padding: 20px;
    @media ($bp-larger-than-desktop) {
      width: 33.33%;
      flex-shrink: 0;
      padding: 67px 43px 0 12px;
    }
  }
}
.content{
  &__top{
    padding-bottom: 50px;
    padding-top: 50px;
    @media ($bp-larger-than-desktop) {
      padding-top: 0;
    }
  }
  &__box {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    @media ($bp-larger-than-desktop) {
      max-width: 762px;
      position: relative;
    }
  }
  &__image{
    text-align: center;
    @media ($bp-larger-than-desktop) {
      position: absolute;
      left: 15px;
      top: 0;
    }
  }
  &__text{
    margin-top: 20px;
    text-align: center;
    @media ($bp-larger-than-desktop) {
      margin-top: 60px;
      padding: 34px 0 0;
      padding-left: 200px;
      text-align: center;
      min-height: 313px;
    }
  }
  &__text-top{
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 24px;
    @media ($bp-larger-than-desktop) {
      font-size: 48px;
      margin-bottom: 45px;
    }
  }
  &__text-bottom{
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    @media ($bp-larger-than-desktop) {
      font-size: 41px;
      margin-bottom: auto;
    }
  }
  &__bottom{
    margin-bottom: 50px;
    @media ($bp-larger-than-desktop) {
      margin-bottom: 58px;
    }
  }
}
.content-lead{
  &__button{
    width: 100%;
    background-color: #49c0d0;
    text-align: center;
    display: block;
    height: 70px;
    line-height: 70px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    font-family: Arial, sans-serif;

    @media ($bp-larger-than-desktop) {
      line-height: 94px;
      font-size: 30px;
      height: 94px;
    }
    &:hover{
      color: #fff;
      opacity: 0.9;
    }
  }
}
.lead-form{
  background-color: #e9e9e9;
  padding: 20px 15px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media ($bp-larger-than-tablet) {
    padding: 27px 76px 38px;
    max-width: 900px;
  }
  input[type="text"]{
    border: 0 none;
    height: 40px;
    line-height: 40px;
    width: 100%;
    font-size: 18px;
    font-family: Arial, sans-serif;
    @media ($bp-larger-than-desktop) {
      height: 70px;
      line-height: 70px;
      font-size: 24px;
    }
  }
  textarea{
    border: 0 none;
    resize: none;
    width: 100%;
    height: 80px;
    font-size: 18px;
    font-family: Arial, sans-serif;
    margin-bottom: 30px;
    @media ($bp-larger-than-desktop) {
      height: 158px;
      font-size: 24px;
      margin-bottom: 47px;
    }
  }
  &__label{
    font-size: 22px;
    font-weight: bold;
    color: #000;
    font-family: Arial, sans-serif;
    margin-bottom: 8px;
    @media ($bp-larger-than-desktop) {
      font-size: 30px;
      margin-bottom: 13px;
    }
  }

  &__item{
    +.lead-form__item{
      margin-top: 20px;
      @media ($bp-larger-than-desktop) {
        margin-top: 30px;
      }
    }
  }
  &__btn{
    background-color: #49c0d0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    font-family: Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    padding: 15px 36px;
    border: none;
    display: block;
    @media ($bp-larger-than-desktop) {
      padding: 15px 154px;
      font-size: 30px;
    }
    &:hover{
      color: #fff;
      opacity: 0.9;
    }
  }
}
.footer{
  background-color: #deeafb;
  &__list{
    @media ($bp-larger-than-desktop) {
      display: flex;
      justify-content: space-between;
      padding-left: 55px;
      padding-right: 40px;
    }
  }
  &__item{

    //height: 250px;
    width: 100%;
    margin-left: 1px;
    margin-right: 1px;
    padding: 20px;
    margin-bottom: 2px;
    @media ($bp-larger-than-desktop) {
      //margin-bottom: auto;
      padding: 35px 5px 26px 26px;
    }
    +.footer__item{
      border-left: 1px solid #fff;
    }
  }
  &__icon{
    position: relative;
    height: 80px;
    //margin-bottom: 4px;
    display: none;
    @media($bp-larger-than-desktop) {
      display: block;
    }
    &:before{
      position: absolute;
      content: "";
      display: block;
      left: 23px;
      top: 0;
    }
    &--address{
      &:before{
        @include sprite($address);
      }
    }
    &--phone{
      &:before{
        @include sprite($phone);
        left: 10px;
      }
    }
    &--mail{
      &:before{
        @include sprite($mail);
      }
    }
  }
  &__caption{
    font-size: 18px;
    font-weight: bold;
    font-family: Arial, sans-serif;
    &--address{
      padding-left: 4px;
      margin-bottom: 19px;
      @media ($bp-larger-than-desktop) {
        padding-left: 24px;
      }
    }
    &--phone{
      padding-left: 3px;
      margin-bottom: 22px;
      @media ($bp-larger-than-desktop) {
        padding-left: 3px;
      }
    }
    &--mail{
      padding-left: 3px;
      margin-bottom: 22px;
      @media ($bp-larger-than-desktop) {
        padding-left: 27px;
      }
    }
  }
  &__text{
    font-size: 18px;
    font-weight: bold;
    font-family: Arial, sans-serif;
    a{
      text-decoration: none;
      color: #000;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
.in-mobile{
  display: none;
  @media ($bp-larger-than-desktophd) {
    display: block;
  }
}